var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "background-color": "white",
      "overflow-y": "auto",
      "overflow-x": "hidden",
      "width": "100vw"
    }
  }, [_c('div', {}, [_c('ul', {
    staticClass: "outer-ul"
  }, [_c('li', [_c('b-navbar', {
    staticClass: "p-0",
    attrs: {
      "toggleable": ""
    }
  }, [_c('b-navbar-toggle', {
    staticClass: "border-0 w-100 p-0",
    attrs: {
      "target": "markets-toggle-collapse"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var expanded = _ref.expanded;
        return [_c('div', {
          staticClass: "d-flex w-100 justify-content-between align-items-center my-toggle menu"
        }, [_c('p', {
          staticClass: "my-p"
        }, [_vm._v(_vm._s(_vm.$t('nav.markets.markets')))]), _c('div', {
          staticClass: "d-flex align-items-center justify-content-center iconBox",
          staticStyle: {
            "width": "32px",
            "height": "32px"
          }
        }, [expanded ? _c('font-awesome-icon', {
          staticClass: "iconChev",
          attrs: {
            "icon": "chevron-up"
          }
        }) : _c('font-awesome-icon', {
          staticClass: "iconChev",
          attrs: {
            "icon": "chevron-down"
          }
        })], 1)])];
      }
    }])
  }), _c('b-collapse', {
    ref: "nav-1",
    staticStyle: {
      "background-color": "#e6e8ec"
    },
    attrs: {
      "id": "markets-toggle-collapse",
      "is-nav": ""
    }
  }, [_c('b-navbar-nav', {
    staticClass: "menu1"
  }, [_c('b-nav-item', {
    staticClass: "my-nav-items"
  }, [_c('router-link', {
    staticClass: "link d-flex",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": {
        name: 'Markets',
        query: {
          marketType: 'Forex'
        }
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('Forex'), _c('p', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.markets.forex')) + " ")])], 1)], 1), _c('b-nav-item', {
    staticClass: "my-nav-items"
  }, [_c('router-link', {
    staticClass: "link d-flex",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": {
        name: 'Markets',
        query: {
          marketType: 'Shares'
        }
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('Shares'), _c('p', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.markets.shares')) + " ")])], 1)], 1), _c('b-nav-item', {
    staticClass: "my-nav-items"
  }, [_c('router-link', {
    staticClass: "link d-flex",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": {
        name: 'Markets',
        query: {
          marketType: 'Indices'
        }
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('Indices'), _c('p', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.markets.indices')) + " ")])], 1)], 1), _c('b-nav-item', {
    staticClass: "my-nav-items"
  }, [_c('router-link', {
    staticClass: "link d-flex",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": {
        name: 'Markets',
        query: {
          marketType: 'Commodities'
        }
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('Commodities'), _c('p', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.markets.commodities')) + " ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('li', {
    staticClass: "li-link menu1"
  }, [_c('router-link', {
    staticClass: "link w-100",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/accounts-and-fees"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('p', {
    staticClass: "my-p w-100"
  }, [_vm._v(_vm._s(_vm.$t('nav.accounts and fees')))])])], 1), _c('li', {
    staticClass: "li-link menu1"
  }, [_c('router-link', {
    staticClass: "link w-100",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/trading-platform"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('p', {
    staticClass: "my-p w-100"
  }, [_vm._v(_vm._s(_vm.$t('nav.trading platform')))])])], 1), _c('li', {
    staticClass: "li-link menu1"
  }, [_c('a', {
    staticClass: "link w-100",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "https://nozax.kb.help/",
      "target": "_blank"
    }
  }, [_c('p', {
    staticClass: "my-p w-100"
  }, [_vm._v(_vm._s(_vm.$t('nav.knowledge base')))])])]), _c('li', [_c('b-navbar', {
    staticClass: "p-0",
    attrs: {
      "toggleable": ""
    }
  }, [_c('b-navbar-toggle', {
    staticClass: "border-0 w-100 p-0 menu1",
    attrs: {
      "target": "company-toggle-collapse"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var expanded = _ref2.expanded;
        return [_c('div', {
          staticClass: "d-flex w-100 justify-content-between align-items-center my-toggle"
        }, [_c('p', {
          staticClass: "my-p"
        }, [_vm._v(_vm._s(_vm.$t('nav.company.company')))]), _c('div', {
          staticClass: "d-flex align-items-center justify-content-center iconBox",
          staticStyle: {
            "width": "32px",
            "height": "32px"
          }
        }, [expanded ? _c('font-awesome-icon', {
          staticClass: "iconChev",
          attrs: {
            "icon": "chevron-up"
          }
        }) : _c('font-awesome-icon', {
          staticClass: "iconChev",
          attrs: {
            "icon": "chevron-down"
          }
        })], 1)])];
      }
    }])
  }), _c('b-collapse', {
    ref: "nav-2",
    staticStyle: {
      "background-color": "#e6e8ec"
    },
    attrs: {
      "id": "company-toggle-collapse",
      "is-nav": ""
    }
  }, [_c('b-navbar-nav', {
    staticClass: "menu1"
  }, [_c('b-nav-item', {
    staticClass: "my-nav-items"
  }, [_c('router-link', {
    staticClass: "link d-flex",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/company/about-us"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('AboutUs'), _c('p', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.company.about us')) + " ")])], 1)], 1), _c('b-nav-item', {
    staticClass: "my-nav-items"
  }, [_c('router-link', {
    staticClass: "link d-flex",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/company/careers"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('Careers'), _c('p', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.company.careers')) + " ")])], 1)], 1), _c('b-nav-item', {
    staticClass: "my-nav-items"
  }, [_c('router-link', {
    staticClass: "link d-flex",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/company/affiliates"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('Affiliates'), _c('p', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.company.affiliates')) + " ")])], 1)], 1), _c('b-nav-item', {
    staticClass: "my-nav-items"
  }, [_c('router-link', {
    staticClass: "link d-flex",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/company/legal-documents"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('LegalDocumentation'), _c('p', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.company.legal documentation')) + " ")])], 1)], 1), _c('b-nav-item', {
    staticClass: "my-nav-items"
  }, [_c('router-link', {
    staticClass: "link d-flex",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/company/contact-us"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('ContactUs'), _c('p', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.company.contact us')) + " ")])], 1)], 1)], 1)], 1)], 1)], 1)])]), _c('div', {
    staticClass: "d-flex justify-content-center d-md-none"
  }, [_vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-16-24 d-flex mr-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToSignUp();
      }
    }
  }, [_c('p', {
    staticClass: "button-medium text-white-button w-100"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.sign up')) + " ")])]) : _vm._e(), _vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-16-24-outline link d-flex ml-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToLogin();
      }
    }
  }, [_c('h5', {
    staticClass: "button-medium w-100"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.login')) + " ")])]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }