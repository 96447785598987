<template>
  <div id="app" style="contain: paint">
    <div v-if="$route.name !== 'LegalDocument' || !$route.params.document">
      <div
        class="risk-warning__container"
        style="box-shadow: inset 0 -1px 0 0 #e6e8ec"
        ref="risk_warning">
        <div class="d-flex risk-warning__wrapper">
          <!-- <div class="entity__selector">
          <IdentitySelector v-if="api_url" />
        </div>
        <hr class="d-block d-md-none m-0" /> -->

          <span class="riskWarning">
            {{ $t("nav.risk-warning") }}
          </span>
        </div>
      </div>
      <Nav
        :langs="langs"
        :fontMapping="fontMapping"
        class="w-100 bg-white"
        style="position: sticky !important; top: 0px; z-index: 999"
        :languageFlags="languageFlags" />

      <div
        :class="!['home', 'AboutUs'].includes($route?.name) ? 'container' : ''"
        class="dynamicMarginTop">
        <div :class="!['home', 'AboutUs'].includes($route.name) ? 'row' : ''">
          <div :class="!['home', 'AboutUs'].includes($route.name) ? 'col-12 p-0' : ''">
            <router-view :identity="identity"></router-view>
          </div>
        </div>
      </div>
      <Footer v-if="api_url" />
    </div>
    <div v-else><router-view :identity="identity"></router-view></div>
  </div>
</template>

<script>
import Footer from "@/components/footer/Footer.vue";
import Nav from "@/components/nav/Nav.vue";
import axios from "axios";
import identities from "./identities.json";
import MultipleIdentities from "@/libs/MultipleIdentities";
import IdentitySelector from "./components/nav/IdentitySelector.vue";
import Vue from "vue";
import { mapActions } from "vuex";

import enMessage from "@/libs/i18n/en.json";
import jaMessage from "@/libs/i18n/ja.json";

export default {
  name: "App",
  components: { Footer, Nav, IdentitySelector },

  data() {
    return {
      identity: {},
      api_url: "",
      languageFlags: [],
      langs: [],
      fontMapping: {
        lo: "Phetsarath OT",
        ja: "Meiryo UI",
      },
    };
  },

  methods: {
    ...mapActions(["fetchCompanySettings", "setIdentity"]),

    async getCountry() {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/site_settings/get_location`);
      const country = response.data?.[0]?.data?.country;
      return country;
    },

    getIdentity(country) {
      let appENV = JSON.parse(process.env.VUE_APP_ENV || "[]");
      appENV = appENV.length > 0 ? appENV : identities;
      localStorage.setItem("APP_ENV", JSON.stringify(appENV));
      localStorage.setItem("country", country);
      const identity = MultipleIdentities("APP_IDENTITY", appENV, country);
      this.identity = identity;
      return identity;
    },

    initProperties(identity) {
      this.setIdentity(identity);
      this.api_url = identity.api_url;
      console.log(Vue.prototype);
    },

    async fetchFavicon() {
      try {
        document.getElementById("favicon").href = "";
        let res = await this.$axios.get(`site_settings/get_company_favicon`);
        document.getElementById("favicon").href = res.data;
      } catch (err) {
        console.error(err);
      }
    },

    getTranslation() {
      this.$axios
        .get(`/public_site_settings/front_page_translations?recursive`)
        .then((res) => {
          let msg = {};
          for (var key in res.data.children) {
            msg[key] = res.data.children[key].settings.translations;
            this.$i18n.setLocaleMessage(key, msg[key]);
          }
          this.langs = Object.keys(msg);
        })
        .catch((err) => {
          console.error(err);
          this.$i18n.setLocaleMessage("en", enMessage);
          this.$i18n.setLocaleMessage("ja", jaMessage);
        });
    },

    getLanguageFlags() {
      const lang = localStorage.getItem("lang");
      if (lang) {
        this.$i18n.locale = lang;
        document.body.style.fontFamily = this.fontMapping[lang]
          ? this.fontMapping[lang]
          : "Open Sans";
        for (const className of [
          "hero-custom__title",
          "hero-custom__text",
          "subtitle-bodyLarge",
          "subtitle-bodyLarge-to-mobile-body-regular",
          "pre-title",
          "roboto",
        ]) {
          var eles = document.getElementsByClassName(className);
          for (let i = 0; i < eles.length; i++) {
            eles[i].style.fontFamily = this.fontMapping[lang] ? this.fontMapping[lang] : "Roboto";
          }
        }
        for (const className of ["h1", "h2", "h3", "h4", "h5", "h6"]) {
          var eles = document.getElementsByTagName(className);
          for (let i = 0; i < eles.length; i++) {
            eles[i].style.fontFamily = this.fontMapping[lang] ? this.fontMapping[lang] : "Roboto";
          }
        }
      }
      this.$axios
        .post(`/site_settings/get_language_flags`)
        .then((res) => {
          this.languageFlags = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },

  async mounted() {
    try {
      const company = await axios.get(
        `${process.env.VUE_APP_API_URL}/public_site_settings/company`
      );
      if (
        !document.referrer.toLowerCase().includes(company.data.settings.company_name.toLowerCase())
      ) {
        document.cookie = `referrer=${
          document.referrer
        };Domain=${company.data.settings.company_name.toLowerCase()}.com;SameSite=Lax`;
        document.cookie = `referral_params=${JSON.stringify(
          Object.fromEntries(new URLSearchParams(window.location.search).entries())
        )};Domain=${company.data.settings.company_name.toLowerCase()}.com;SameSite=Lax`;
      }
    } catch (error) {
      console.error(error);
    }
    let country = await this.getCountry();
    let identity = this.getIdentity(country);
    this.initProperties(identity);
    await this.fetchCompanySettings();
    this.getTranslation();
    this.fetchFavicon();
    this.getLanguageFlags();
  },

  watch: {},
};
</script>
<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a:hover {
  color: inherit !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.dynamicMarginTop {
  @media (max-width: 575px) {
    // margin-top: 0px;
  }

  @media (max-width: 767px) and (min-width: 576px) {
    // margin-top: 217px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    // margin-top: 134px;
  }

  @media (min-width: 1200px) {
    // margin-top: 145px;
  }
}

.risk-warning__container {
}

.risk-warning__wrapper {
  @media (min-width: 1200px) {
    max-width: 1440px;
    margin: 0 auto;
  }

  @media (min-width: 1200px) {
  }
}

.riskWarning {
  text-align: left;
  color: #39454c !important;
  //font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;

  @media (max-width: 767px) {
    margin: 12px 31px;
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    text-align: center;
    margin: 12px 40px 12px 0px;
  }

  @media (min-width: 1200px) {
    text-align: center;
    margin: 12px 80px 12px auto;
  }
}

.entity__selector {
  @media (max-width: 767px) {
    margin: 12px 31px;
    position: relative;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 12px auto 12px 24px;
  }

  @media (min-width: 1200px) {
    margin: 12px auto 12px 80px;
  }
}
</style>
