var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('hr', {
    staticClass: "d-none d-md-block",
    staticStyle: {
      "margin": "0"
    }
  }), _c('div', {
    staticClass: "box d-none d-md-block"
  }, [_c('div', {
    staticClass: "footer-copyright d-flex flex-column flex-md-row justify-content-around"
  }, [_c('div', {
    staticClass: "d-flex flex-row justify-content-between align-items-center w-100"
  }, [_c('p', {
    staticClass: "body-small-text light-theme-title mt-3 v"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.copyright", {
    year: new Date().getFullYear()
  })) + " ")]), _c('div', {
    staticClass: "footer-icons d-flex",
    staticStyle: {
      "gap": "25px"
    }
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.youtube.com/channel/UCI_laATLRDbc7M-CXdCQaWQ"
    }
  }, [_c('YoutubeIcon')], 1), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.linkedin.com/company/nozax/"
    }
  }, [_c('LinkedinIcon')], 1), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.facebook.com/NozaxGlobal"
    }
  }, [_c('FbIcon')], 1), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://twitter.com/NozaxGlobal"
    }
  }, [_c('TwitterIcon')], 1), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.instagram.com/nozax_global/"
    }
  }, [_c('InstaIcon')], 1)])])])]), _c('hr', {
    staticStyle: {
      "margin": "0"
    }
  }), _c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "footer-children d-flex flex-md-row flex-column position-relative"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "footer-nav d-none d-md-flex flex-column justify-content-between",
    staticStyle: {
      "gap": "24px"
    }
  }, [_c('router-link', {
    staticClass: "navigation-bold light-theme-title",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/markets"
    }
  }, [_vm._v(_vm._s(_vm.$t("nav.markets.markets")))]), _c('router-link', {
    staticClass: "navigation-bold light-theme-title",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/accounts-and-fees"
    }
  }, [_vm._v(_vm._s(_vm.$t("nav.accounts and fees")))]), _c('router-link', {
    staticClass: "navigation-bold light-theme-title",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/trading-platform"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.trading platform")))]), _c('a', {
    staticClass: "navigation-bold light-theme-title",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "https://nozax.kb.help/",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.knowledge base")))]), _c('router-link', {
    staticClass: "navigation-bold light-theme-title",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "to": "/about-us"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.company.company")) + " ")])], 1), _c('div', {
    staticClass: "p-0 d-flex flex-column d-md-none",
    staticStyle: {
      "gap": "24px"
    }
  }, [_c('router-link', {
    staticClass: "navigation-bold light-theme-title",
    attrs: {
      "to": "/markets"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.markets.markets")) + " ")]), _c('router-link', {
    staticClass: "navigation-bold light-theme-title",
    attrs: {
      "to": "/accounts-and-fees"
    }
  }, [_vm._v(_vm._s(_vm.$t("nav.accounts and fees")))]), _c('router-link', {
    staticClass: "navigation-bold light-theme-title",
    attrs: {
      "to": "/trading-platform"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.trading platform")) + " ")]), _c('a', {
    staticClass: "navigation-bold light-theme-title",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "https://nozax.kb.help/",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.knowledge base")))]), _c('router-link', {
    staticClass: "navigation-bold light-theme-title",
    attrs: {
      "to": "/about-us"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.company.company")) + " ")])], 1), _c('hr', {
    staticClass: "d-md-none",
    staticStyle: {
      "margin": "2px 0 !important"
    }
  }), _c('div', {
    staticClass: "d-flex contact flex-grow-1"
  }, [_c('div', {
    staticStyle: {
      "width": "167px",
      "display": "flex",
      "flex-direction": "column",
      "gap": "24px"
    }
  }, [_c('h6', {
    staticClass: "body-black-text light-theme-title body"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.address.address")) + " ")]), _vm.entity === 'nozax_jsc' ? _c('div', [_c('p', {
    staticClass: "body-small-text light-theme-title x"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.address.1")) + " ")]), _c('p', {
    staticClass: "body-small-text light-theme-title x",
    staticStyle: {
      "margin-bottom": "8px !important",
      "margin-top": "8px !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.address.2")) + " ")]), _c('p', {
    staticClass: "body-small-text light-theme-title x",
    staticStyle: {
      "margin-bottom": "8px !important",
      "margin-top": "8px !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.address.3")) + " ")])]) : _vm._e(), _vm.entity === 'nozax_sv' ? _c('div', [_c('p', {
    staticClass: "body-small-text light-theme-title x"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.address.sv_floor")) + " ")]), _c('p', {
    staticClass: "body-small-text light-theme-title x",
    staticStyle: {
      "margin-bottom": "8px !important",
      "margin-top": "8px !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.address.sv_building")) + " ")]), _c('p', {
    staticClass: "body-small-text light-theme-title x",
    staticStyle: {
      "margin-bottom": "8px !important",
      "margin-top": "8px !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.address.sv_town")) + " ")]), _c('p', {
    staticClass: "body-small-text light-theme-title x",
    staticStyle: {
      "margin-bottom": "8px !important",
      "margin-top": "8px !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.address.sv_street")) + " ")])]) : _vm._e(), _c('h6', {
    staticClass: "body-black-text light-theme-title body"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.address.telephone")) + " ")]), _c('p', {
    staticClass: "body-small-text light-theme-title x"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.address.telephone_number")) + " ")]), _c('p', {
    staticClass: "contact-us"
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none",
      "text-transform": "uppercase"
    },
    attrs: {
      "to": "/company/contact-us"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.contact-us")) + " ")])], 1)])]), _c('hr', {
    staticClass: "d-md-none",
    staticStyle: {
      "margin": "2px 0 !important"
    }
  }), _c('div', {
    staticClass: "footer-newsletter d-flex flex-grow-1 justify-content-md-end"
  }, [_c('div', {
    staticClass: "footer-newsletter-content d-flex flex-column",
    staticStyle: {
      "gap": "24px"
    }
  }, [_c('h6', {
    staticClass: "body-black-text light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.newsletter")) + " ")]), _c('p', {
    staticClass: "body-small-text light-theme-title c"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.subscribe")) + " ")]), _c('form', {
    staticStyle: {
      "margin": "0 !important"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitEmail.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "mail-sub"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email,
      expression: "email"
    }],
    staticClass: "mail-input body-paragraph-14",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('nav.enter email')
    },
    domProps: {
      "value": _vm.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.email = $event.target.value;
      }
    }
  }), _c('b-button', {
    staticClass: "mail-sub-button",
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v(" ➜ ")])], 1)]), _c('div', {
    staticClass: "box m-0 d-block d-md-none"
  }, [_c('div', {}, [_c('div', {}, [_c('p', {
    staticStyle: {
      "font-family": "'Open Sans', font-weight: 400",
      "font-size": "14px",
      "line-height": "120%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.copyright", {
    year: new Date().getFullYear()
  })) + " ")]), _c('div', {
    staticClass: "footer-icons d-flex my-3",
    staticStyle: {
      "gap": "25px"
    }
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.youtube.com/channel/UCI_laATLRDbc7M-CXdCQaWQ"
    }
  }, [_c('YoutubeIcon')], 1), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.linkedin.com/company/nozax/"
    }
  }, [_c('LinkedinIcon')], 1), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.facebook.com/NozaxGlobal"
    }
  }, [_c('FbIcon')], 1), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://twitter.com/NozaxGlobal"
    }
  }, [_c('TwitterIcon')], 1), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.instagram.com/nozax_global/"
    }
  }, [_c('InstaIcon')], 1)])])])])])])])]), _c('hr', {
    staticStyle: {
      "margin": "0"
    }
  }), _c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "footer-text"
  }, [_c('p', {
    staticClass: "mb-0 font-weight-semibold"
  }, [_c('span', {
    staticClass: "company-policy",
    on: {
      "click": function ($event) {
        return _vm.showPolicies('privacy');
      }
    }
  }, [_vm._v("Privacy Policy ")]), _vm._v(" | "), _c('span', {
    staticClass: "company-policy",
    on: {
      "click": function ($event) {
        return _vm.showPolicies('cookie');
      }
    }
  }, [_vm._v("Cookie Policy")]), _vm._v(" | "), _c('span', {
    staticClass: "company-policy",
    on: {
      "click": function ($event) {
        return _vm.showPolicies('term-conditions');
      }
    }
  }, [_vm._v("Website terms and conditions")])]), _c('br'), _c('p', {
    staticClass: "body-small-text text-grey-primary-main-900"
  }, [_c('span', {
    staticClass: "re-2 license-link",
    domProps: {
      "innerHTML": _vm._s(_vm.$t("nav.legal1-3"))
    }
  })]), _vm.entity === 'nozax_sv' ? _c('br') : _vm._e(), _vm.entity === 'nozax_sv' ? _c('p', {
    staticClass: "body-small-text text-grey-primary-main-900"
  }, [_c('span', {
    staticClass: "re-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.legal1-4")) + " ")])]) : _vm._e(), _c('br'), _c('p', {
    staticClass: "body-small-text text-grey-primary-main-900"
  }, [_c('span', {
    staticClass: "re-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.legal3-1")) + " ")]), _c('span', {
    staticClass: "re-2 risk-disclosure-link",
    domProps: {
      "innerHTML": _vm._s(_vm.$t("nav.legal3-2", {
        entity: _vm.entity
      }))
    }
  }), _c('span', {
    staticClass: "re-2",
    staticStyle: {
      "margin-left": "-4px"
    }
  }, [_vm._v(".")]), _c('span', {
    staticClass: "re-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.legal3-3")) + " ")])]), _c('br'), _c('p', {
    staticClass: "body-small-text text-grey-primary-main-900"
  }, [_c('span', {
    staticClass: "re-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.legal4-1")) + " ")]), _c('span', {
    staticClass: "re-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.legal4-2")) + " ")])]), _c('br'), _c('p', {
    staticClass: "body-small-text text-grey-primary-main-900"
  }, [_c('span', {
    staticClass: "re-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.legal4-3")) + " ")])])])]), !_vm.openCookies ? _c('b-modal', {
    attrs: {
      "id": "cookies__modal",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "cookies__container"
  }, [_c('div', {
    staticClass: "cookies__content"
  }, [_c('div', {
    staticClass: "d-flex d-md-none d-lg-block cookies__text-wrapper"
  }, [_c('p', {
    staticClass: "cookies__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("cookies.info1")) + " ")]), _c('p', {
    staticClass: "cookies__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("cookies.info2")) + " "), _c('span', {
    staticStyle: {
      "font-weight": "600",
      "cursor": "pointer",
      "text-decoration": "underline"
    },
    on: {
      "click": _vm.openCookiesPolicy
    }
  }, [_vm._v(_vm._s(_vm.$t("cookies.cookie-policy")))]), _vm._v(". ")])]), _c('p', {
    staticClass: "d-none d-md-block d-lg-none cookies__text-wrapper mb-0"
  }, [_c('span', {
    staticClass: "cookies__text mr-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("cookies.info3")))]), _c('span', {
    staticStyle: {
      "font-weight": "600",
      "cursor": "pointer",
      "text-decoration": "underline"
    },
    on: {
      "click": _vm.openCookiesPolicy
    }
  }, [_vm._v(_vm._s(_vm.$t("cookies.cookie-policy")))]), _vm._v(". ")]), _c('div', {
    staticClass: "cookies__buttons"
  }, [_c('b-button', {
    staticClass: "cookies__button",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.closeModalCookies
    }
  }, [_vm._v(_vm._s(_vm.$t("nav.footer.close")))]), _c('b-button', {
    staticClass: "cookies__button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.closeModalCookies
    }
  }, [_vm._v(_vm._s(_vm.$t("nav.footer.accept")))])], 1)])])]) : _vm._e(), _c('div')], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-none d-md-block logo"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/Logo.svg"),
      "alt": ""
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-md-none"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/Group.svg"),
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }