<script>
import Forex from './icons/Forex.vue';
import Shares from './icons/Shares.vue';
import Indices from './icons/Indices.vue';
import Commodities from './icons/Commodities.vue';
import Crypto from './icons/Crypto.vue';
import AboutUs from './icons/AboutUs.vue';
import Careers from './icons/Careers.vue';
import Affiliates from './icons/Affiliates.vue';
import LegalDocumentation from './icons/LegalDocumentation.vue';
import ContactUs from './icons/ContactUs.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['show'],
  computed: {
    ...mapGetters({
      portal_url: 'portal_url'
    })
  },
  methods: {
    close() {
      this.$emit('close');
    },

    goToSignUp() {
      if (this.portal_url) window.location.href = `${this.portal_url}/register`;
    },
    goToLogin() {
      if (this.portal_url) window.location.href = `${this.portal_url}/login`;
    }
  },
  components: {
    Forex,
    Shares,
    Indices,
    Commodities,
    Crypto,
    AboutUs,
    Careers,
    Affiliates,
    LegalDocumentation,
    ContactUs
  }
};
</script>
<template>
  <div
    style="background-color: white; overflow-y: auto;overflow-x: hidden; !important; width: 100vw">
    <div class="">
      <ul class="outer-ul">
        <li>
          <b-navbar toggleable class="p-0">
            <b-navbar-toggle target="markets-toggle-collapse" class="border-0 w-100 p-0">
              <template #default="{ expanded }">
                <div class="d-flex w-100 justify-content-between align-items-center my-toggle menu">
                  <p class="my-p">{{ $t('nav.markets.markets') }}</p>
                  <div
                    style="width: 32px; height: 32px"
                    class="d-flex align-items-center justify-content-center iconBox">
                    <font-awesome-icon v-if="expanded" icon="chevron-up" class="iconChev" />
                    <font-awesome-icon v-else icon="chevron-down" class="iconChev" />
                  </div>
                </div>
              </template>
            </b-navbar-toggle>

            <b-collapse
              id="markets-toggle-collapse"
              style="background-color: #e6e8ec"
              is-nav
              ref="nav-1">
              <b-navbar-nav class="menu1">
                <b-nav-item class="my-nav-items"
                  ><router-link
                    class="link d-flex"
                    style="text-decoration: none"
                    @click.native="close"
                    :to="{ name: 'Markets', query: { marketType: 'Forex' } }"
                    ><Forex />
                    <p class="light-theme-title">
                      {{ $t('nav.markets.forex') }}
                    </p></router-link
                  ></b-nav-item
                >
                <b-nav-item class="my-nav-items"
                  ><router-link
                    class="link d-flex"
                    style="text-decoration: none"
                    @click.native="close"
                    :to="{ name: 'Markets', query: { marketType: 'Shares' } }"
                    ><Shares />
                    <p class="light-theme-title">
                      {{ $t('nav.markets.shares') }}
                    </p></router-link
                  ></b-nav-item
                >
                <b-nav-item class="my-nav-items"
                  ><router-link
                    class="link d-flex"
                    style="text-decoration: none"
                    @click.native="close"
                    :to="{ name: 'Markets', query: { marketType: 'Indices' } }"
                    ><Indices />
                    <p class="light-theme-title">
                      {{ $t('nav.markets.indices') }}
                    </p></router-link
                  ></b-nav-item
                >
                <b-nav-item class="my-nav-items"
                  ><router-link
                    class="link d-flex"
                    style="text-decoration: none"
                    @click.native="close"
                    :to="{
                      name: 'Markets',
                      query: { marketType: 'Commodities' }
                    }"
                    ><Commodities />
                    <p class="light-theme-title">
                      {{ $t('nav.markets.commodities') }}
                    </p></router-link
                  ></b-nav-item
                >
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </li>
        <li class="li-link menu1">
          <router-link
            class="link w-100"
            style="text-decoration: none"
            @click.native="close"
            to="/accounts-and-fees">
            <p class="my-p w-100">{{ $t('nav.accounts and fees') }}</p>
          </router-link>
        </li>
        <li class="li-link menu1">
          <router-link
            class="link w-100"
            style="text-decoration: none"
            @click.native="close"
            to="/trading-platform">
            <p class="my-p w-100">{{ $t('nav.trading platform') }}</p>
          </router-link>
        </li>
        <li class="li-link menu1">
          <a
            class="link w-100"
            style="text-decoration: none"
            href="https://nozax.kb.help/"
            target="_blank">
            <p class="my-p w-100">{{ $t('nav.knowledge base') }}</p>
          </a>
        </li>
        <li>
          <b-navbar toggleable class="p-0">
            <b-navbar-toggle target="company-toggle-collapse" class="border-0 w-100 p-0 menu1">
              <template #default="{ expanded }">
                <div class="d-flex w-100 justify-content-between align-items-center my-toggle">
                  <p class="my-p">{{ $t('nav.company.company') }}</p>
                  <div
                    style="width: 32px; height: 32px"
                    class="d-flex align-items-center justify-content-center iconBox">
                    <font-awesome-icon v-if="expanded" icon="chevron-up" class="iconChev" />
                    <font-awesome-icon v-else icon="chevron-down" class="iconChev" />
                  </div>
                </div>
              </template>
            </b-navbar-toggle>

            <b-collapse
              id="company-toggle-collapse"
              is-nav
              style="background-color: #e6e8ec"
              ref="nav-2">
              <b-navbar-nav class="menu1">
                <b-nav-item class="my-nav-items"
                  ><router-link
                    class="link d-flex"
                    style="text-decoration: none"
                    @click.native="close"
                    to="/company/about-us"
                    ><AboutUs />
                    <p class="light-theme-title">
                      {{ $t('nav.company.about us') }}
                    </p></router-link
                  ></b-nav-item
                >
                <b-nav-item class="my-nav-items"
                  ><router-link
                    class="link d-flex"
                    style="text-decoration: none"
                    @click.native="close"
                    to="/company/careers"
                    ><Careers />
                    <p class="light-theme-title">
                      {{ $t('nav.company.careers') }}
                    </p></router-link
                  ></b-nav-item
                >
                <b-nav-item class="my-nav-items"
                  ><router-link
                    class="link d-flex"
                    style="text-decoration: none"
                    @click.native="close"
                    to="/company/affiliates"
                    ><Affiliates />
                    <p class="light-theme-title">
                      {{ $t('nav.company.affiliates') }}
                    </p></router-link
                  ></b-nav-item
                >
                <b-nav-item class="my-nav-items"
                  ><router-link
                    class="link d-flex"
                    style="text-decoration: none"
                    @click.native="close"
                    to="/company/legal-documents"
                    ><LegalDocumentation />
                    <p class="light-theme-title">
                      {{ $t('nav.company.legal documentation') }}
                    </p></router-link
                  ></b-nav-item
                >
                <b-nav-item class="my-nav-items"
                  ><router-link
                    class="link d-flex"
                    style="text-decoration: none"
                    @click.native="close"
                    to="/company/contact-us"
                    ><ContactUs />
                    <p class="light-theme-title">
                      {{ $t('nav.company.contact us') }}
                    </p></router-link
                  ></b-nav-item
                >
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </li>
      </ul>
    </div>

    <div class="d-flex justify-content-center d-md-none">
      <b-button
        v-if="portal_url"
        variant="primary"
        class="round-btn-16-24 d-flex mr-2"
        @click="goToSignUp()">
        <p class="button-medium text-white-button w-100">
          {{ $t('nav.sign up') }}
        </p>
      </b-button>

      <b-button
        v-if="portal_url"
        variant="outline-primary"
        class="round-btn-16-24-outline link d-flex ml-2"
        @click="goToLogin()">
        <h5 class="button-medium w-100">
          {{ $t('nav.login') }}
        </h5>
      </b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  /* width: 83%; */
  @media screen and (max-width: 767px) {
    width: 100% !important;
    margin: auto 28px auto 32px !important;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    width: 100% !important;
    margin: auto 40px auto 40px !important;
  }
  @media screen and (min-width: 1200px) {
    width: 100% !important;
    margin: 64px 80px auto 80px !important;
  }
}
.menu1 {
  /* width: 83%; */
  @media screen and (max-width: 767px) {
    width: 100% !important;
    margin: auto 28px auto 32px !important;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    width: 100% !important;
    margin: auto 40px auto 40px !important;
  }
  @media screen and (min-width: 1200px) {
    width: 100% !important;
    margin: auto 80px auto 80px !important;
  }
}
.outer-ul {
  list-style: none;
  /* margin-top: 50px; */
  /* width: 100%; */
  @media screen and (max-width: 767px) {
    margin-top: 0 !important;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin: 64px auto 0 auto !important;
  }
}
li {
  width: 100%;
  /* identical to box height, or 123% */

  // color: #f3f6fb !important;
}
p {
  color: #484c57;
  padding: 0;
}

.my-toggle,
.li-link {
  height: 64px;
  /* margin: auto 32px auto 34px !important; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    max-width: calc(100vw - 28px - 32px);
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    max-width: calc(100vw - 40px - 40px);
  }
  @media (min-width: 1200px) {
    max-width: calc(100vw - 80px - 80px);
  }
}
.menu {
  @media (max-width: 767px) {
    max-width: calc(100vw - 28px - 32px);
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    max-width: calc(100vw - 40px - 40px);
  }
  @media (min-width: 1200px) {
    max-width: calc(100vw - 80px - 80px);
  }
}

.my-nav-items {
  margin: 4px auto;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  /* @media (max-width: 767px) {
    max-width: 307px;
  } */
}
/* .my-nav-items:hover,
.li-link:hover,
.my-toggle:hover {
} */
.my-p {
  margin: 0;
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  height: 25px !important;
  color: #484c57;
  @media (max-width: 767px) {
    font-size: 18px !important;
  }
}
/* .my-p2 {
  margin: 0;
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  height: 25px !important;
  @media (max-width: 767px) {
    font-size: 18px !important;
  }
} */

.light-theme-title {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  margin-left: 8px;
}

.iconChev {
  color: #39354c;
  @media (max-width: 767px) {
    width: 12px !important;
  }
}
.iconBox {
}
.btn {
  @media (max-width: 767px) {
    width: 145px !important;
  }
}
.not-collapsed {
  color: #07b53b !important;
}
.not-collapsed p,
.not-collapsed .iconChev {
  color: #07b53b !important;
}

.navbar-collapse {
  width: 100vw !important;
  background-color: #f3f6fb !important;
}
</style>
