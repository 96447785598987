<template>
  <div>
    <div class="navbar-container d-flex justify-content-center align-items-center">
      <div class="main-nav">
        <!-- Left block -->
        <div class="d-flex align-items-center left-block">
          <!-- COMPANY LOGO -->
          <router-link class="link nav-logo d-flex" to="/">
            <img src="@/assets/Group.svg" alt="brand-logo" class="nozax-logo" />
            <div
              style="background: #e6e8ec; width: 1px; margin-left: 19px; margin-right: 19px"
              class="d-none d-md-block"></div>
          </router-link>

          <!-- NAVIGATION -->
          <b-navbar-nav
            class="flex-row d-none d-xxl-flex justify-content-between"
            style="height: 38px; gap: 30px">
            <b-nav-item
              class="position-relative nav-padding-10"
              v-click-outside="outside1"
              @click="isMarketDropdownShowing = !isMarketDropdownShowing"
              @mouseover="
                (isLanguageOptionShowing = false),
                  (isCompanyDropdownShowing = false),
                  (isMarketDropdownShowing = true)
              "
              @mouseleave="delayCloseMarketDD()">
              <router-link
                to="/markets"
                class="navigation-semi-bold light-theme-title d-inline-block light-theme-title greenHover">
                {{ $t('nav.markets.markets') }}
              </router-link>
              <ChevronDown style="width: 18px; height: 18px; margin-left: 10px" />
              <div
                v-if="isMarketDropdownShowing"
                id="market-dropdown"
                @mouseover="() => clearTimeout(myMarketTimeout)">
                <div class="arrow"></div>
                <div class="links">
                  <div v-for="(i, ind) in navs">
                    <div
                      class="d-flex justify-content-start align-items-center"
                      :class="marketType == i ? 'text-success' : ''"
                      style="width: 224px"
                      @click="goToMarket(i)">
                      <component :is="i" style="width: 20px; height: 20px; margin-right: 8px" />
                      <p class="navigation-semi-bold light-theme-title greenHover">
                        {{ $t(`nav.markets.${i.toLowerCase()}`) }}
                      </p>
                    </div>
                    <div
                      style="
                        width: 100%;
                        height: 1px;
                        background: #e6e8ec;
                        margin-bottom: 17px;
                        margin-top: 12px;
                      "
                      v-if="ind !== navs.length - 1"></div>
                  </div>
                </div>
              </div>
            </b-nav-item>

            <b-nav-item class="nav-padding-10">
              <router-link class="link navigation-semi-bold" to="/accounts-and-fees">{{
                $t('nav.accounts and fees')
              }}</router-link>
            </b-nav-item>

            <b-nav-item class="nav-padding-10"
              ><router-link class="link navigation-semi-bold" to="/trading-platform">{{
                $t('nav.trading platform')
              }}</router-link></b-nav-item
            >
            <b-nav-item
              class="nav-padding-10 navigation-semi-bold greenHover"
              href="https://nozax.kb.help/"
              target="_blank">
              {{ $t('nav.knowledge base') }}</b-nav-item
            >

            <b-nav-item
              class="position-relative nav-padding-10"
              v-click-outside="outside2"
              @click="isCompanyDropdownShowing = !isCompanyDropdownShowing"
              @mouseover="
                (isLanguageOptionShowing = false),
                  (isCompanyDropdownShowing = true),
                  (isMarketDropdownShowing = false)
              "
              @mouseleave="delayCloseCompanyDD()">
              <router-link
                to="/company"
                class="navigation-semi-bold light-theme-title light-theme-title d-inline-block greenHover">
                {{ $t('nav.company.company') }}
              </router-link>
              <ChevronDown style="width: 18px; height: 18px; margin-left: 10px" />
              <div
                v-if="isCompanyDropdownShowing"
                id="company-dropdown"
                @mouseover="() => clearTimeout(myCompanyTimeout)">
                <div class="arrow"></div>
                <div class="links">
                  <router-link
                    class="navigation-semi-bold"
                    to="/company/about-us"
                    style="text-decoration: none; width: 100%">
                    <div
                      class="d-flex justify-content-start align-items-center greenHover"
                      style="width: 224px">
                      <AboutUs style="width: 20px; height: 20px; margin-right: 8px" />
                      <p class="navigation-semi-bold light-theme-title greenHover">
                        {{ $t('nav.company.about us') }}
                      </p>
                    </div>
                  </router-link>
                  <div
                    style="
                      width: 100%;
                      height: 1px;
                      background: #e6e8ec;
                      margin-bottom: 17px;
                      margin-top: 12px;
                    "></div>
                  <router-link
                    class="navigation-semi-bold light-theme-title light-theme-title"
                    to="/company/careers"
                    style="text-decoration: none; width: 100%">
                    <div
                      class="d-flex justify-content-start align-items-center greenHover"
                      style="width: 224px">
                      <Careers style="width: 20px; height: 20px; margin-right: 8px" />
                      <p class="navigation-semi-bold light-theme-title greenHover">
                        {{ $t('nav.company.careers') }}
                      </p>
                    </div>
                  </router-link>
                  <div
                    style="
                      width: 100%;
                      height: 1px;
                      background: #e6e8ec;
                      margin-bottom: 17px;
                      margin-top: 12px;
                    "></div>
                  <router-link
                    class="navigation-semi-bold light-theme-title light-theme-title"
                    to="/company/affiliates"
                    style="text-decoration: none; width: 100%">
                    <div
                      class="d-flex justify-content-start align-items-center greenHover"
                      style="width: 224px">
                      <Affiliates style="width: 20px; height: 20px; margin-right: 8px" />
                      <p class="navigation-semi-bold light-theme-title greenHover">
                        {{ $t('nav.company.affiliates') }}
                      </p>
                    </div>
                  </router-link>
                  <div
                    style="
                      width: 100%;
                      height: 1px;
                      background: #e6e8ec;
                      margin-bottom: 17px;
                      margin-top: 12px;
                    "></div>
                  <router-link
                    class="navigation-semi-bold light-theme-title light-theme-title"
                    to="/company/legal-documents"
                    style="text-decoration: none; width: 100%">
                    <div
                      class="d-flex justify-content-start align-items-center greenHover"
                      style="width: 224px">
                      <LegalDocumentation style="width: 20px; height: 20px; margin-right: 8px" />
                      <p class="navigation-semi-bold light-theme-title greenHover">
                        {{ $t('nav.company.legal documentation') }}
                      </p>
                    </div>
                  </router-link>
                  <div
                    style="
                      width: 100%;
                      height: 1px;
                      background: #e6e8ec;
                      margin-bottom: 17px;
                      margin-top: 12px;
                    "></div>
                  <router-link
                    class="navigation-semi-bold light-theme-title light-theme-title"
                    to="/company/contact-us"
                    style="text-decoration: none; width: 100%">
                    <div
                      class="d-flex justify-content-start align-items-center greenHover"
                      style="width: 224px">
                      <ContactUs style="width: 20px; height: 20px; margin-right: 8px" />
                      <p class="navigation-semi-bold light-theme-title greenHover">
                        {{ $t('nav.company.contact us') }}
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
            </b-nav-item>
          </b-navbar-nav>
        </div>

        <!-- Right Block -->
        <b-navbar-nav
          class="right-block d-md-flex flex-row align-items-center"
          style="height: 38px">
          <!-- Toggle language -->

          <b-nav-item
            class="position-relative nav-padding-12-16 d-flex align-items-center"
            style="height: 38px"
            v-click-outside="outside3"
            @click="isLanguageOptionShowing = !isLanguageOptionShowing"
            @mouseover="
              (isLanguageOptionShowing = true),
                (isCompanyDropdownShowing = false),
                (isMarketDropdownShowing = false)
            "
            @mouseleave="delayCloseLanguageDD()">
            <span class="button-small d-inline-block light-theme-title text-uppercase">
              {{ locales.find((l) => l.code == $i18n.locale)?.code || lan }}
            </span>
            <ChevronDown style="width: 32px; height: 32px; margin-left: 6px" />
            <div
              v-if="isLanguageOptionShowing"
              id="language-dropdown"
              @mouseover="() => clearTimeout(myLanguageTimeout)">
              <div class="arrow"></div>
              <div class="lang-links">
                <!-- <p
                  class="navigation-semi-bold"
                  style="margin-bottom: 16px; color: #888f94"
                >
                  {{ $t("nav.language") }}
                </p> -->
                <div
                  v-for="(lan, index) in langs"
                  :key="index"
                  class="d-flex justify-content-start align-items-center greenHover lan borderClass py-2"
                  @click="setLang(lan)">
                  <img
                    :src="getImgUrl(lan)"
                    style="margin-right: 8px" />
                  <p class="navigation-semi-bold light-theme-title greenHover">
                    {{ locales.find((l) => l.code == lan)?.label_en || lan }}
                  </p>
                  <div style="height: 1px; background: #e6e8ec; margin: 12px auto 17px"></div>
                </div>

                <!--  <div
                  class="d-flex justify-content-start align-items-center greenHover lan"
                  @click="setLang('ja')"
                >
                  <b-img
                    :src="require('./icons/japanese.svg')"
                    alt="JP Flag"
                    style="width: 20px; height: 20px; margin-right: 8px"
                  ></b-img>
                  <p class="navigation-semi-bold light-theme-title">
                    {{ $t("nav.Japanese") }}
                  </p>
                </div> -->
              </div>
            </div>
          </b-nav-item>
          <!-- button login -->
          <b-button
            v-if="portal_url"
            variant="primary"
            class="round-btn-12-16 d-none d-md-block"
            style="margin-right: 8px"
            @click="goToSignUp()">
            <p class="button-small text-white-button">
              {{ $t('nav.sign up') }}
            </p>
          </b-button>

          <b-button
            v-if="portal_url"
            variant="outline-primary"
            class="round-btn-12-16-outline d-none d-md-block"
            @click="goToLogin()"
            ><p class="button-small">
              {{ $t('nav.login') }}
            </p></b-button
          >

          <div @click="show = !show" class="d-xxl-none x-icon">
            <HamMenu class="w-100 h-100" v-if="!show" /><XIcon v-else class="w-100 h-100" />
          </div>
        </b-navbar-nav>
      </div>
    </div>
    <transition name="slide">
      <TabletAndMobileNavBar
        v-if="show"
        class="d-xxl-none list"
        :show.sync="show"
        @close="show = false" />
    </transition>
  </div>
</template>
<script>
import Forex from './icons/Forex.vue';
import Shares from './icons/Shares.vue';
import Indices from './icons/Indices.vue';
import Commodities from './icons/Commodities.vue';
import Crypto from './icons/Crypto.vue';
import AboutUs from './icons/AboutUs.vue';
import Careers from './icons/Careers.vue';
import Affiliates from './icons/Affiliates.vue';
import LegalDocumentation from './icons/LegalDocumentation.vue';
import ContactUs from './icons/ContactUs.vue';
import TabletAndMobileNavBar from './TabletAndMobileNavBar.vue';
import ChevronDown from './icons/ChevronDown.vue';
import HamMenu from './icons/HamMenu.vue';
import XIcon from './icons/XIcon.vue';
import { locales } from '@/constants/config';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Forex,
    Shares,
    Indices,
    Commodities,
    Crypto,
    AboutUs,
    Careers,
    Affiliates,
    LegalDocumentation,
    ContactUs,
    TabletAndMobileNavBar,
    ChevronDown,
    HamMenu,
    XIcon
  },
  props: ['langs', 'languageFlags', 'fontMapping'],
  data() {
    return {
      show: false,
      locales,
      isMarketDropdownShowing: false,
      isCompanyDropdownShowing: false,
      isLanguageOptionShowing: false,
      myMarketTimeout: null,
      myCompanyTimeout: null,
      myLanguageTimeout: null,
      marketType: '',
      navs: ['Forex', 'Shares', 'Indices', 'Commodities'],
    };
  },
  computed: {
    ...mapGetters({
      portal_url: 'portal_url'
    })
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.marketType = to.query.marketType ? to.query.marketType : '';
      this.show = false;
    },
    show: function () {
      if (this.show) {
        document.documentElement.style.overflow = 'hidden';
        return;
      }
      document.documentElement.style.overflow = 'auto';
    }
  },
  // created() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  methods: {
    getImgUrl(lang) {
      return require(`@/assets/language_flags/${lang}.svg`)
    },
    clearTimeout(t) {
      clearTimeout(t);
    },
    delayCloseMarketDD() {
      this.myMarketTimeout = setTimeout(() => {
        this.isMarketDropdownShowing = false;
      }, 200);
    },
    delayCloseCompanyDD() {
      this.myCompanyTimeout = setTimeout(() => {
        this.isCompanyDropdownShowing = false;
      }, 200);
    },
    delayCloseLanguageDD() {
      this.myLanguageTimeout = setTimeout(() => {
        this.isLanguageOptionShowing = false;
      }, 200);
    },
    handleScroll() {
      // if (e.path[1].pageYOffset > 0) {
      //   this.$refs.risk_warning.style.display = "none";
      // } else {
      //   this.$refs.risk_warning.style.display = "block";
      // }
    },
    setLang(code) {
      localStorage.setItem('lang', code);
      this.$i18n.locale = code;
      document.body.style.fontFamily = this.fontMapping[code]
        ? this.fontMapping[code]
        : 'Open Sans';
      for (const className of [
        'hero-custom__title',
        'hero-custom__text',
        'subtitle-bodyLarge',
        'subtitle-bodyLarge-to-mobile-body-regular',
        'pre-title',
        'roboto'
      ]) {
        var eles = document.getElementsByClassName(className);
        for (let i = 0; i < eles.length; i++) {
          eles[i].style.fontFamily = this.fontMapping[code] ? this.fontMapping[code] : 'Roboto';
        }
      }
      for (const className of ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']) {
        var eles = document.getElementsByTagName(className);
        for (let i = 0; i < eles.length; i++) {
          eles[i].style.fontFamily = this.fontMapping[code] ? this.fontMapping[code] : 'Roboto';
        }
      }
    },
    outside1: function () {
      this.isMarketDropdownShowing = false;
    },
    outside2: function () {
      this.isCompanyDropdownShowing = false;
    },
    outside3: function () {
      this.isLanguageOptionShowing = false;
    },
    goToLogin() {
      if (this.portal_url) window.location.href = `${this.portal_url}/login?lang=${this.$i18n.locale}`;
    },
    goToSignUp() {
      if (this.portal_url) window.location.href = `${this.portal_url}/register?lang=${this.$i18n.locale}`;
    },
    goToMarket(type) {
      this.$router.push({ path: '/markets', query: { marketType: type } }).catch(() => {});
    }
  },
  mounted() {},
  directives: {
    'click-outside': {
      bind: function (el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
          const compName = vNode.context.name;
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
          if (compName) {
            warn += `Found in component '${compName}'`;
          }

          console.warn(warn);
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;

        // add Event Listeners
        document.addEventListener('click', handler);
      },

      unbind: function (el) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.left-block {
  @media (max-width: 767px) {
    margin: 21.83px auto 21.83px 32px !important;
  }

  @media (min-width: 768px) {
    margin: 22px auto 22px 24px !important;
  }
  @media (min-width: 1024) {
    margin: 22px auto 22px 32px !important;
  }
  @media (min-width: 1200px) {
    margin: 20.5px auto 20.5px 80px !important;
  }
}
.right-block {
  @media (max-width: 767px) {
    margin: 22px 32px 22px auto !important;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 20px 40px 20px auto !important;
  }
  @media (min-width: 1200px) {
    margin: 20.5px 80px 20.5px auto !important;
  }
}
.arrow {
  content: '';
  position: absolute;
  top: -10px;
  left: 45%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid white;
}
.arrow::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: -2px;
  right: -12px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;

  border-bottom: 12px solid #e6e8ec;
}
#market-dropdown {
  position: absolute;
  left: -80px;
  top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  /* Neutrals/8 */

  background: white;
  /* depth/6 */

  box-shadow: 0px 16px 64px -48px rgba(31, 47, 70, 0.15);
  /* border: 1px solid black; */
  border-radius: 12px;
  border: 1px solid #e6e8ec;
  z-index: 1;
}

#company-dropdown {
  position: absolute;
  right: -65px;
  top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  /* Neutrals/8 */
  background: white;
  /* depth/6 */

  box-shadow: 0px 16px 64px -48px rgba(31, 47, 70, 0.15);
  /* border: 1px solid black; */
  border-radius: 12px;
  border: 1px solid #e6e8ec;

  z-index: 1;
}
#language-dropdown {
  position: absolute;
  right: -47px;
  top: 62px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border: 1px solid #e6e8ec;

  /* Neutrals/8 */
  background: white;
  /* depth/6 */

  box-shadow: 0px 16px 64px -48px rgba(31, 47, 70, 0.15);
  /* border: 1px solid black; */
  border-radius: 12px;
  z-index: 1;

  @media (max-width: 767px) {
    right: -40px;
  }
}

.navbar-container {
  background-color: #f8f8f9;
  box-shadow: inset 0 -1px 0 0 #e6e8ec;
  padding: 0;
}

.list {
  background: #fcfcfd;
  height: calc(100vh - 235px);
  width: 100vw;
  // position: fixed;
  z-index: 5;

  transform-origin: top;
  transition: transform 0.3s ease-in-out;
  @media (max-width: 767px) {
    // top: 82px;
  }
}
.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}
.main-nav {
  padding: 0 !important;
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  /* margin: ; */
}

.nav-padding-10 {
  padding: 10px;
}
.nav-padding-12-16 {
  padding: 10px 16px;
}

.nav-link {
  line-height: normal;
}
.nav-link:hover {
  color: #07b53b;
}

.x-icon {
  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
    margin-left: 2.11px;
  }
  @media (min-width: 768px) {
    width: 44px;
    height: 44px;
    margin-left: 3px;
  }
}

.nozax-logo {
  width: auto;

  @media screen and (min-width: 768px) {
    height: 32px;
  }
  @media screen and (max-width: 767px) {
    height: 24px;
  }
}

.lang-tog {
  @media (min-width: 768px) {
    margin-right: 3px;
  }
}
.links {
  padding: 16px;
}
.lang-links {
  padding: 0 16px 0 16px;
}
.greenHover:hover {
  color: #07b53b !important;
}
.d-xxl-none {
  @media (min-width: 1440px) {
    display: none !important;
  }
}
.d-xxl-flex {
  @media (min-width: 1440px) {
    display: flex !important;
  }
}
.lan {
  @media (min-width: 768px) {
    width: 126px;
  }
}

.borderClass {
  border-bottom: 1px solid #e6e8ec;
  &:last-child {
    border: 0;
  }
}
</style>
