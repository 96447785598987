<template>
  <div class="section">
    <div class="container text-center">
      <h2 class="light-theme-title">
        {{ $t('home.partners.title') }}
      </h2>
      <!-- <h6 class="body-paragraph-bold-to-custom-to-h3 light-theme-title">
        {{ $t('home.partners.payment-partners') }}
      </h6>
      <div class="row px-md-5">
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <a href="https://en.swissquote.com/" target="_blank">
            <img src="../svg/partner/Swissquote.svg" alt="" />
          </a>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <a href="https://zotapay.com/" target="_blank">
            <img src="../svg/partner/zotapay.svg" alt="" />
          </a>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <a href="https://b2binpay.com/" target="_blank">
            <img src="../svg/partner/b2binpay.svg" alt="" />
          </a>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <a href="https://www.sticpay.com/" target="_blank">
            <img src="../svg/partner/sticpay.svg" alt="" />
          </a>
        </div>
      </div> -->
      <h6 class="body-paragraph-bold-to-custom-to-h3 light-theme-title">
        {{ $t('home.partners.platform-liquidity-partners') }}
      </h6>
      <div class="row px-md-5">
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <a href="https://www.metaquotes.net/" target="_blank">
            <img src="../svg/partner/metaquotes.svg" alt="" />
          </a>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <a href="https://www.equiti.com/apac-en/" target="_blank">
            <img src="../svg/partner/equity.svg" alt="" />
          </a>
        </div>
        <div class="col-6 col-md-3 d-flex align-items-center justify-content-center">
          <a href="https://en.swissquote.com/" target="_blank">
            <img src="../svg/partner/Swissquote.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section {
  @media (max-width: 767px) {
    margin-top: 150px !important;
  }
  @media (min-width: 768px) {
    margin-top: 119px !important;
  }
}
h6 {
  margin-top: 48px !important;
  margin-bottom: 24px !important;
}
.row {
  justify-content: space-around !important;
  @media (min-width: 768px) {
    gap: 28px 0px !important;
  }
  gap: 28px 0px !important;
}
img {
  width: 190px !important;
  height: 140px !important;
}
</style>
