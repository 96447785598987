<template>
  <div class="footer" style="overflow: hidden">
    <hr class="d-none d-md-block" style="margin: 0" />
    <!-- Copyright -->
    <div class="box d-none d-md-block">
      <div
        class="footer-copyright d-flex flex-column flex-md-row justify-content-around"
      >
        <div
          class="d-flex flex-row justify-content-between align-items-center w-100"
        >
          <p class="body-small-text light-theme-title mt-3 v">
            {{ $t("nav.copyright", {year: new Date().getFullYear()}) }}
          </p>

          <div class="footer-icons d-flex" style="gap: 25px">
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCI_laATLRDbc7M-CXdCQaWQ"
            >
              <YoutubeIcon />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/nozax/"
            >
              <LinkedinIcon />
            </a>
            <a target="_blank" href="https://www.facebook.com/NozaxGlobal">
              <FbIcon />
            </a>
            <a target="_blank" href="https://twitter.com/NozaxGlobal">
              <TwitterIcon />
            </a>
            <a target="_blank" href="https://www.instagram.com/nozax_global/">
              <InstaIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
    <hr style="margin: 0" />
    <div class="box">
      <div
        class="footer-children d-flex flex-md-row flex-column position-relative"
      >
        <!-- Logo -->
        <div class="d-none d-md-block logo">
          <img src="@/assets/Logo.svg" alt="" />
        </div>
        <div class="d-md-none">
          <img src="@/assets/Group.svg" alt="" />
        </div>
        <!-- Footer Nav -->

        <div
          class="footer-nav d-none d-md-flex flex-column justify-content-between"
          style="gap: 24px"
        >
          <router-link
            class="navigation-bold light-theme-title"
            to="/markets"
            style="text-decoration: none;"
            >{{ $t("nav.markets.markets") }}</router-link
          >
          <router-link
            class="navigation-bold light-theme-title"
            to="/accounts-and-fees"
            style="text-decoration: none;"
            >{{ $t("nav.accounts and fees") }}</router-link
          >
          <router-link
            class="navigation-bold light-theme-title"
            to="/trading-platform"
            style="text-decoration: none;"
          >
            {{ $t("nav.trading platform") }}</router-link
          >
          <a
            class="navigation-bold light-theme-title"
            href="https://nozax.kb.help/"
            style="text-decoration: none;"
            target="_blank"
          >
            {{ $t("nav.knowledge base") }}</a
          >
          <router-link
            class="navigation-bold light-theme-title"
            to="/about-us"
            style="text-decoration: none;"
          >
            {{ $t("nav.company.company") }}
          </router-link>
        </div>

        <div class="p-0 d-flex flex-column d-md-none" style="gap: 24px">
          <router-link class="navigation-bold light-theme-title" to="/markets">
            {{ $t("nav.markets.markets") }}
          </router-link>
          <router-link
            class="navigation-bold light-theme-title"
            to="/accounts-and-fees"
            >{{ $t("nav.accounts and fees") }}</router-link
          >
          <router-link
            class="navigation-bold light-theme-title"
            to="/trading-platform"
          >
            {{ $t("nav.trading platform") }}
          </router-link>
          <a
            class="navigation-bold light-theme-title"
            href="https://nozax.kb.help/"
            target="_blank"
            style="text-decoration: none;"
          >
            {{ $t("nav.knowledge base") }}</a
          >
          <router-link class="navigation-bold light-theme-title" to="/about-us">
            {{ $t("nav.company.company") }}
          </router-link>
        </div>

        <hr class="d-md-none" style="margin: 2px 0 !important" />

        <!-- Contact -->

        <div class="d-flex contact flex-grow-1">
          <div
            style="
              width: 167px;
              display: flex;
              flex-direction: column;
              gap: 24px;
            "
          >
            <h6 class="body-black-text light-theme-title body">
              {{ $t("nav.address.address") }}
            </h6>
            <div v-if="entity === 'nozax_jsc'">
              <p class="body-small-text light-theme-title x">
                {{ $t("nav.address.1") }}
              </p>
              <p
                class="body-small-text light-theme-title x"
                style="
                  margin-bottom: 8px !important;
                  margin-top: 8px !important;
                "
              >
                {{ $t("nav.address.2") }}
              </p>
              <p
                class="body-small-text light-theme-title x"
                style="
                  margin-bottom: 8px !important;
                  margin-top: 8px !important;
                "
              >
                {{ $t("nav.address.3") }}
              </p>
            </div>
            <div v-if="entity === 'nozax_sv'">
              <p class="body-small-text light-theme-title x">
                {{ $t("nav.address.sv_floor") }}
              </p>
              <p
                class="body-small-text light-theme-title x"
                style="
                  margin-bottom: 8px !important;
                  margin-top: 8px !important;
                "
              >
                {{ $t("nav.address.sv_building") }}
              </p>
              <p
                class="body-small-text light-theme-title x"
                style="
                  margin-bottom: 8px !important;
                  margin-top: 8px !important;
                "
              >
                {{ $t("nav.address.sv_town") }}
              </p>
              <p
                class="body-small-text light-theme-title x"
                style="
                  margin-bottom: 8px !important;
                  margin-top: 8px !important;
                "
              >
                {{ $t("nav.address.sv_street") }}
              </p>
            </div>
            <h6 class="body-black-text light-theme-title body">
              {{ $t("nav.address.telephone") }}
            </h6>
            <p class="body-small-text light-theme-title x">
              {{ $t("nav.address.telephone_number") }}
            </p>
            <p class="contact-us">
              <router-link
                to="/company/contact-us"
                style="text-decoration: none; text-transform: uppercase"
              >
                {{ $t("nav.contact-us") }}
              </router-link>
            </p>
          </div>
        </div>
        <hr class="d-md-none" style="margin: 2px 0 !important" />
        <!-- Newsletter -->
        <div
          class="footer-newsletter d-flex flex-grow-1 justify-content-md-end"
        >
          <div
            class="footer-newsletter-content d-flex flex-column"
            style="gap: 24px"
          >
            <h6 class="body-black-text light-theme-title">
              {{ $t("nav.newsletter") }}
            </h6>
            <p class="body-small-text light-theme-title c">
              {{ $t("nav.subscribe") }}
            </p>
            <form @submit.prevent="submitEmail" style="margin: 0 !important">
              <div class="mail-sub">
                <input
                  type="text"
                  class="mail-input body-paragraph-14"
                  :placeholder="$t('nav.enter email')"
                  v-model="email"
                />
                <b-button
                  variant="primary"
                  class="mail-sub-button"
                  type="submit"
                >
                  &#10140;
                </b-button>
              </div>
            </form>
            <div class="box m-0 d-block d-md-none">
              <div class="">
                <div class="">
                  <p style="font-family: 'Open Sans', font-weight: 400; font-size: 14px; line-height: 120%">
                    {{ $t("nav.copyright", {year: new Date().getFullYear()}) }}
                  </p>
                  <div class="footer-icons d-flex my-3" style="gap: 25px">
                    <!-- <a href="https://line.me/R/ti/p/%40185bhtec">
                      <LineIcon />
                    </a> -->
                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UCI_laATLRDbc7M-CXdCQaWQ"
                    >
                      <YoutubeIcon />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/nozax/"
                    >
                      <LinkedinIcon />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/NozaxGlobal"
                    >
                      <FbIcon />
                    </a>
                    <a target="_blank" href="https://twitter.com/NozaxGlobal">
                      <TwitterIcon />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/nozax_global/"
                    >
                      <InstaIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr style="margin: 0" />
    <!-- Footer text: intro, cookie, restricted regions -->
    <div class="box">
      <div class="footer-text">
        <p class="mb-0 font-weight-semibold">
          <span class="company-policy" @click="showPolicies('privacy')"
            >Privacy Policy
          </span>
          |
          <span class="company-policy" @click="showPolicies('cookie')"
            >Cookie Policy</span
          >
          |
          <span class="company-policy" @click="showPolicies('term-conditions')"
            >Website terms and conditions</span
          >
        </p>
        <br />
        <p class="body-small-text text-grey-primary-main-900">
          <span class="re-2 license-link" v-html='$t("nav.legal1-3")'>
          </span>
        </p>
        <br v-if="entity === 'nozax_sv'"/>
        <p class="body-small-text text-grey-primary-main-900" v-if="entity === 'nozax_sv'">
          <span class="re-2">
            {{ $t("nav.legal1-4") }}
          </span>
        </p>
        <br />
        <p class="body-small-text text-grey-primary-main-900">
          <span class="re-1">
            {{ $t("nav.legal3-1") }}
          </span>
          <span class="re-2 risk-disclosure-link" v-html='$t("nav.legal3-2", {entity})'>
          </span>
          <span class="re-2" style="margin-left: -4px">.</span>
          <span class="re-2">
            {{ $t("nav.legal3-3") }}
          </span>
        </p>
        <br />
        <p class="body-small-text text-grey-primary-main-900">
          <span class="re-1">
            {{ $t("nav.legal4-1") }}
          </span>

          <span class="re-2">
            {{ $t("nav.legal4-2") }}
          </span>
        </p>
        <br />
        <p class="body-small-text text-grey-primary-main-900">
          <span class="re-2">
            {{ $t("nav.legal4-3") }}
          </span>
        </p>
      </div>
    </div>

    <b-modal
      id="cookies__modal"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      v-if="!openCookies"
    >
      <div class="cookies__container">
        <div class="cookies__content">
          <div class="d-flex d-md-none d-lg-block cookies__text-wrapper">
            <p class="cookies__text">
              {{ $t("cookies.info1") }}
            </p>
            <p class="cookies__text">
              {{ $t("cookies.info2") }}
              <span
                @click="openCookiesPolicy"
                style="
                  font-weight: 600;
                  cursor: pointer;
                  text-decoration: underline;
                "
                >{{ $t("cookies.cookie-policy") }}</span
              >.
            </p>
          </div>
          <p class="d-none d-md-block d-lg-none cookies__text-wrapper mb-0">
            <span class="cookies__text mr-1"> {{ $t("cookies.info3") }}</span>
            <span
              @click="openCookiesPolicy"
              style="
                font-weight: 600;
                cursor: pointer;
                text-decoration: underline;
              "
              >{{ $t("cookies.cookie-policy") }}</span
            >.
          </p>
          <div class="cookies__buttons">
            <b-button
              variant="outline-primary"
              class="cookies__button"
              @click="closeModalCookies"
              >{{ $t("nav.footer.close") }}</b-button
            >
            <b-button
              variant="primary"
              class="cookies__button"
              @click="closeModalCookies"
              >{{ $t("nav.footer.accept") }}</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>

    <div></div>
  </div>
</template>
<script>
import YoutubeIcon from "./components/YoutubeIcon.vue";
import LinkedinIcon from "./components/Linkedin.vue";
import FbIcon from "./components/FbIcon.vue";
import TwitterIcon from "./components/TwitterIcon.vue";
import InstaIcon from "./components/InstaIcon.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    // LineIcon,
    YoutubeIcon,
    LinkedinIcon,
    FbIcon,
    TwitterIcon,
    InstaIcon,
  },
  data() {
    return {
      email: "",
      openCookies: localStorage.getItem("cookie_accepted"),
    };
  },
  mounted() {
    this.$bvModal.show("cookies__modal");
  },
  computed: {
    ...mapGetters({
      getSiteSettings: "getSiteSettings",
    }),
    entity() {
      return localStorage.getItem("APP_IDENTITY");
    },
  },

  methods: {
    openCookiesPolicy() {
      // const  = localStorage.getItem("APP_IDENTITY");
      window.open(
        `PDF/policies/${this.entity}/cookie.pdf`,
        `${this.entity}/cookie`,
        "_blank",
        "fullscreen=yes"
      );
    },
    closeModalCookies() {
      localStorage.setItem("cookie_accepted", true);
      let modal = document.querySelector(".fade");
      modal.style.transition = "fade 0.15s ease";
      this.$bvModal.hide("cookies__modal");
    },
    showPolicies(document) {
      // const entity = localStorage.getItem("APP_IDENTITY");
      window.open(
        `PDF/policies/${this.entity}/${document}.pdf`,
        `${this.entity}/${document}`,
        "_blank",
        "fullscreen=yes"
      );
    },
    submitEmail() {
      this.email = "";
      alert("Submit Email!");
    },
  },
};
</script>

<style lang="scss">
.license-link a {
  text-decoration: none;
  &:hover {
    color: #07b53b !important;
    text-decoration: none;
  }
}
.risk-disclosure-link a {
  text-decoration: none;
  &:hover {
    color: #07b53b !important;
    text-decoration: none;
  }
}
.modal-open {
  overflow: hidden !important;
  padding-right: 0 !important;
}
</style>

<style lang="scss" scoped>
::v-deep .modal-content {
  border: 0 !important;
}
::v-deep .modal-body {
  padding: 0;
}
::v-deep .fade {
  transition: opacity 1s ease-out;
}
::v-deep .modal-dialog {
  transform: none !important;
  transition: transform 0.7s ease-out !important;
  width: 100% !important;
  position: fixed !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  background: #ffffff;
  border: 1px solid #e6e8ec;
}
::v-deep .modal-content {
  border-bottom-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

::v-deep .modal-dialog {
  max-width: unset !important;
  animation: slideUp 0.7s ease;
  margin: 0;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}

.company-policy {
  font-size: 16px;
  line-height: 120%;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: #07b53b !important;
  }
}

.cookies__container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px 0;
  @media screen and (min-width: 767px) {
    padding: 21px 0;
  }
  @media screen and (min-width: 1025px) {
    padding: 24px 0;
  }
}

.cookies__content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 767px) and (max-width: 1023px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
  }
  @media screen and (min-width: 1199px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 160px;
  }
  @media screen and (min-width: 1023px) and (max-width: 1199px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 80px;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin: 0 24px;
  }
}

.cookies__text-wrapper {
  @media screen and (min-width: 1023px) and (max-width: 1199px) {
    max-width: 580px;
    max-height: 54px;
    line-height: 18px;
  }
  @media screen and (min-width: 1199px) {
    max-width: 913px;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }
}
.cookies__text {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  letter-spacing: 0.02em;
  line-height: 18px;
}

.cookies__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.cookies__button {
  height: 48px;
  width: 112px;
  padding: 16px 24px;
  border-radius: 90px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-primary {
  color: #fcfcfd;
  font-weight: 700;
}

.btn-outline-primary {
  color: #39454c;
  font-weight: 700;
  border: 2px solid #07b53b;
}

.footer {
  background-color: white;

  .footer-children {
    @media screen and (max-width: 767px) {
      gap: 30px;
    }
  }
}

.risk-disclosure-text {
  font-weight: 600 !important;
  cursor: pointer;
  font-style: italic;
  color: #616a70;
  font-size: 16px;
  &:hover {
    color: #07b53b !important;
  }
}
.box {
  max-width: 1440px;
  margin: 0 auto;
}
form {
  position: relative;
}
@media (max-width: 767px) {
  form {
    width: 80vw;
    margin-left: 3px;
  }
  .footer-children {
    margin: 64px 32px 16px 32px;
  }
  .footer-copyright {
    margin: 32px 32px 38px 32px;
  }

  .footer-text {
    margin: 24px 32px 23px 32px;
  }
}
@media (max-width: 1199px) and (min-width: 768px) {
  .footer-children {
    /* margin: 80px 80px 64px 80px; */
    margin: 0px 80px 0px 80px;
  }
  .footer-copyright {
    margin: 25px 80px 24px 80px;
  }

  .footer-text {
    margin: 24px 80px 31px 80px;
  }
}
@media (min-width: 1200px) {
  .footer-children {
    /* margin: 80px 160px 64px 160px; */
    margin: 0px 160px 0px 160px;
  }
  .footer-copyright {
    margin: 25px 160px 24px 160px;
  }

  .footer-text {
    margin: 24px 160px;
  }
}
.mail-sub {
  position: relative;
  width: 25;
  @media (max-width: 767px) {
    width: 311px;
    max-width: 100%;
  }
}
.mail-input {
  width: 100%;
  border: 2px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 90px;
  padding: 10px 38px 10px 20px;
  box-sizing: border-box;
  outline: none;
  color: #616a70;
  &:focus {
    border-color: #b1b5c3;
  }
}
.mail-sub-button {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  border: 0;
  outline: none;
  margin: auto;
  padding: 0 10px;
  height: 32px;
  width: 32px;
  border-radius: 50%;

  z-index: 2;
}

.footer-text {
  display: block;
}
.x {
  @media (min-width: 768px) and (max-width: 1199px) {
    font-size: 14px !important;
  }
}
.footer-newsletter-content {
  @media (min-width: 768px) and (max-width: 1199px) {
    max-width: 192px !important;
  }
  @media (min-width: 1200px) {
    max-width: 256px !important;
  }
}

.logo {
  @media (max-width: 991px) {
    /* border-right: 1px solid #e6e8ec; */
    padding-right: 2vw;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    /* border-right: 1px solid #e6e8ec; */
    padding-right: 7vw;
  }
  @media (min-width: 1200px) {
    /* border-right: 1px solid #e6e8ec; */
    padding-right: 150px;
  }
}
.footer-nav {
  @media (min-width: 768px) and (max-width: 1199px) {
    border-right: 1px solid #e6e8ec;
    padding-right: 3vw;
  }
  @media (min-width: 1200px) {
    border-right: 1px solid #e6e8ec;
    padding-right: 90px;
  }
}
.contact {
  @media (max-width: 767px) {
    display: block;
  }
  @media (min-width: 768px) {
    border-right: 1px solid #e6e8ec;
    display: flex;
    justify-content: center;
  }
  /* @media (max-width: 991px) {
    border-right: 1px solid #e6e8ec;
    padding-right: 5vw !important;
    padding-left: 5vw !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-right: 7.7vw !important;
    padding-left: 7.7vw !important;
    border-right: 1px solid #e6e8ec;
  }
  @media (min-width: 1200px) {
    padding-right: 86px !important;
    padding-left: 86px !important;
    border-right: 1px solid #e6e8ec;
  } */
}
.footer-newsletter {
  @media (min-width: 768px) and (max-width: 1199px) {
    max-width: 100% !important;
  }
}
.box,
.footer,
.footer-children {
  height: 100% !important;
}
.c {
  @media (max-width: 767px) {
    width: 86% !important;
    font-size: 16px !important;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    width: 87% !important;
  }
  @media (min-width: 1200px) {
    width: 85% !important;
  }
}
.logo,
.footer-nav,
.contact,
.footer-newsletter {
  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 64px;
  }
}

.re-1 {
  font-weight: 700;
  color: #39454c !important;
}
.re-2 {
  font-weight: 400;
  color: #616a70 !important;
}

.body-small-text {
  @media (max-width: 767px) {
    font-size: 16px !important;
  }
}
.v {
  @media (max-width: 767px) {
    margin-bottom: 33px !important ;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 120% !important;
    /* identical to box height, or 17px */

    letter-spacing: -0.01em;
  }
}
.contact-us {
  margin-top: 13px;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #07b53b;
}
a:hover {
  color: var(--primary) !important;
}

img {
  width: 43px;
  height: 43px;
}
</style>
